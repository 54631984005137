export default function getServiceTypeName(type) {
  switch (type) {
    case 1:
      return 'Консультация в центре'
    case 2:
      return 'Консультация онлайн'
    case 3:
      return 'Консультация на дому'
    case 4:
      return 'Индивидуальные занятия в центре'
    case 5:
      return 'Семейная встреча онлайн'
    case 6:
      return 'Семейная встреча в центре'
    case 7:
      return 'Групповые занятия'
    case 8:
      return 'Индивидуальные занятия на дому'
    case 9:
      return 'Индивидуальные занятия онлайн'
    case 10:
      return 'Фитнес-группа онлайн'
    default:
      return 'тип не указан'
  }
}
