import produce from 'immer'
import moment from 'moment'

import {
  SET_FIELD,
  GET_REQUEST,
  GET_SUCCESS,
  UPDATE_REQUEST,
  UPDATE_SUCCESS
} from '../actions/SettingsActions'

const initialState = {
  isLoading: false,
  params: {
    report_last_date: moment().format('YYYY-MM-DD'),
    report_permanent_access: [],
    unloading_employment_and_statistics_access: [],
    unloading_clients_common_info_access: []
  }
}

export default function(state = initialState, action) {
  switch (action.type) {
    case UPDATE_SUCCESS:
      return produce(state, draftState => {
        draftState.isLoading = false
      })
    case UPDATE_REQUEST:
      return produce(state, draftState => {
        draftState.isLoading = true
      })
    case GET_SUCCESS:
      return produce(state, draftState => {
        draftState.isLoading = false
        draftState.params = action.payload.data
      })
    case GET_REQUEST:
      return produce(state, draftState => {
        draftState.isLoading = true
      })
    case SET_FIELD:
      return produce(state, draftState => {
        draftState.params[action.payload.field] = action.payload.value
      })
    default:
      return state
  }
}
